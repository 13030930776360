import {registerLocaleData} from "@angular/common";
import {Component} from "@angular/core";
import {AuthGuard, AuthService} from "@cloud/shared";
import localeRu from "@angular/common/locales/ru";


@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet> <app-main-page-loader *ngIf=\"(authGuard.loader)\"></app-main-page-loader>"
})
export class AppComponent {
  constructor(public authGuard: AuthGuard,
              private authService: AuthService) {
    registerLocaleData(localeRu, "ru");
    authService.runInitialLoginSequence().then(() => true);
  }
}

// <script id="adc1f4d4-b9af-4ba2-a3eb-a5e54985d6a3" data-yt-url="https://yt.alta-group.ru" src="https://yt.alta-group.ru/static/simplified/form/form-entry.js" data-theme="light" data-lang="ru"></script>
